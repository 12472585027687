<template>
  <div>
    <div class="header">
      <slot name="header" />
    </div>

    <main>
      <slot name="content" />
    </main>
  </div>
</template>

<style scoped>
.header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}
</style>
